<template>
  <div class="container">
    <template id="mainArea">
      <div class="content_box" v-if="questionBankInfo!=null">
        <div class="topTitle">题库详情</div>
        <!-- 基础信息 -->
        <div class="baseInfo">
          <div style="margin-bottom: 26px;">基本信息<i class="el-icon-edit" style="margin-left:5px;color:#1EB180;cursor: pointer;" v-if="questionBankInfo.status==='PENDING_REVIEW'" @click="onEdit"></i></div>
          <div class="item_box display_start">
            <div class="item_info">题号：{{ questionBankInfo.titleNumber }}</div>
            <div class="item_info">图书编码： {{ questionBankInfo.isbn }}</div>
            <div class="item_info">印次：{{ questionBankInfo.printingTimes }}</div>
            <div class="item_info">页码：{{ questionBankInfo.pageNumber }}</div>
          </div>
          <div class="item_box display_start">
            <div class="item_info">来源：{{ questionBankInfo.teachingMaterialName }}</div>
            <div class="item_info">状态：
              <span
                :class="{
              success:questionBankInfo.status==='REVIEW_PASSED',
              danger:questionBankInfo.status==='REVIEW_FAILURE',
              warning:questionBankInfo.status==='PENDING_REVIEW'}">{{
                  questionBankInfo.status | getQuestionStatusName
                }}</span></div>
            <div class="item_info">录题员：{{ questionBankInfo.recorderName }}</div>
            <div class="item_info">审核员：{{ questionBankInfo.auditName }}</div>
          </div>
          <div class="item_info">创建时间：{{ questionBankInfo.createTime }}</div>
          <div class="item_box display_top" v-if="questionBankInfo.status==='REVIEW_FAILURE'">
            <div class="item_info">失败原因：<span style="color: red;line-height:20px">{{
                questionBankInfo.failReason
              }}</span>
            </div>
          </div>
          <div class="item_box display_top">
            <div class="item_img_info">原图：</div>
            <img :src="img.image" :key="img.page" :id="'img_'+img.page" v-for="img in questionBankInfo.images"
                 alt="查看大图"
                 class="cursor"
                 @click="showBigImg(img.page,img.image)"
                 style="max-width: 100px;"/>
          </div>
        </div>
        <!-- 题目信息 -->
        <div class="baseInfo" style="min-height: 267px;">
          <div style="margin-bottom: 26px;">题目信息</div>
          <div class="item_box display_start">
            <div class="item_info">题型：{{ questionBankInfo.questionTypeName }}</div>
            <div class="item_info">年级：{{ questionBankInfo.grade | getGradeName }}</div>
            <div class="item_info">科目：{{ questionBankInfo.subject | getSubjectName }}</div>
          </div>
          <div class="item_info heightStyle"><h3>题干：</h3>
            <br/>
            <ui-mathpad :value="questionBankInfo.stem" v-if="questionBankInfo.stem" class="contentArea" />
            <div v-else>暂无</div>
          </div>
          <div class="item_info heightStyle"><h3>答案：</h3>
            <br/>
            <ui-mathpad :value="questionBankInfo.answer" v-if="questionBankInfo.answer" class="contentArea" />
            <div v-else>暂无</div>
          </div>
          <div class="item_info heightStyle"><h3>解析：</h3>
            <br/>
            <ui-mathpad :value="questionBankInfo.analysis" v-if="questionBankInfo.analysis" class="contentArea" />
            <div v-else>暂无</div>
          </div>
        </div>
        <!-- 功能按钮 -->
        <div class="display_start btnBox">
          <!-- 仅待审核状态可删 -->
          <el-button class="btnFun" @click="deleteQuestionBank" v-if="questionBankInfo.status ==='PENDING_REVIEW'">删除</el-button>
          <el-button class="btnFun btnFun_check border_none" v-if="questionBankInfo.status ==='PENDING_REVIEW'" @click="examine">审核</el-button>
          <el-button class="btnFun btnFun_check border_none" v-if="questionBankInfo.status ==='PENDING_REVIEW'" @click="onEdit">编辑</el-button>
        </div>
      </div>
      <div v-else style="margin:0px auto">
        <el-empty description="查无数据"></el-empty>
      </div>
    </template>
    <el-dialog
      title="审核"
      width="30%"
      :visible.sync="dialogTableVisible">
      <el-radio-group v-model="questionExamineStatus">
        <el-radio label="REVIEW_PASSED">审核通过</el-radio>
        <el-radio label="REVIEW_FAILURE">审核失败</el-radio>
        <el-input
          id="questionFailureMsgArea"
          style="margin-top: 10px;"
          v-if="questionExamineStatus==='REVIEW_FAILURE'"
          type="textarea"
          :rows="4"
          placeholder="请输入失败原因"
          :maxlength="255"
          show-word-limit
          v-model="questionExamineFailureMsg">
        </el-input>
      </el-radio-group>
      <span slot="footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="examineEvent" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
    <template>
      <el-dialog
        title="图片展示"
        id="dialog"
        :visible.sync="dialogVisible"
        :before-close="handleClose">
        <div id="canvas_area">
          <canvas id="show_image_canvas" :height="canvasMaxHeight"
                  :width="canvasMaxWidth"></canvas>
        </div>
        <span slot="footer" class="dialog-footer"></span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import { deleteQuestionBank, getQuestionBankDetails, questionBankAudit } from '@/api/question-bank'
import { SETTING } from '@/config/setting'
import { getGradeName, getQuestionStatusName, getSubjectName } from '@/utils/enums'

export default {
  mounted () {
    getQuestionBankDetails(this.$route.params.id).then(response => {
      if (response.code === SETTING.NUMBER.ZERO) {
        this.questionBankInfo = response.data
        this.questionExamineStatus = this.questionBankInfo.status
      }
    })
  },
  filters: {
    getGradeName (id) {
      return getGradeName(id)
    },
    getSubjectName (id) {
      return getSubjectName(id)
    },
    getQuestionStatusName (id) {
      return getQuestionStatusName(id)
    }
  },
  data () {
    return {
      loading: false,
      canvasMaxWidth: window.innerWidth * 0.8,
      canvasMaxHeight: window.innerWidth * 0.8,
      dialogTableVisible: false,
      questionExamineFailureMsg: null,
      questionExamineStatus: null,
      questionBankInfo: {},
      dialogVisible: false
    }
  },
  methods: {
    onEdit () {
      this.$router.push('/Edit/' + this.$route.params.id)
    },
    handleClose () {
      this.dialogVisible = false
    },
    showBigImg (page, imgSrc) {
      const self = this
      self.dialogVisible = true
      // 需要延迟处理,不然无法获取到DOM节点
      setTimeout(() => {
        const img = document.getElementById('img_' + page)
        const imgHeight = img.naturalHeight
        const imgWidth = img.naturalWidth
        let nHeight = imgHeight
        let nWeight = imgWidth
        let rate = 1
        // 缩放图片
        if (imgWidth > this.canvasMaxWidth) {
          nWeight = this.canvasMaxWidth
          rate = imgWidth / this.canvasMaxHeight
          nHeight = parseInt(imgHeight / rate)
        }
        const canvas = document.getElementById('show_image_canvas')
        canvas.height = nHeight
        const ctx = canvas.getContext('2d')
        // 清空画布
        ctx.clearRect(0, 0, this.canvasMaxWidth, this.canvasMaxHeight)
        // 获取到坐标
        let pos = []
        self.questionBankInfo.coordinate.forEach(item => {
          if (item.page === page) {
            pos = item.coordinate
          }
        })
        ctx.drawImage(img, 0, 0, nWeight, nHeight)
        ctx.beginPath()
        ctx.strokeStyle = 'red'
        pos.forEach(item => {
          ctx.rect(item.x_min / rate, item.y_min / rate, (item.x_max - item.x_min) / rate, (item.y_max - item.y_min) / rate)
        })
        ctx.stroke()
      }, 100)
    },
    // 审核
    examine () {
      this.questionExamineStatus = 'REVIEW_PASSED'
      this.questionExamineFailureMsg = null
      this.dialogTableVisible = true
    },
    examineEvent () {
      this.loading = true
      questionBankAudit({
        questionBankId: this.questionBankInfo.id,
        status: this.questionExamineStatus,
        failReason: this.questionExamineFailureMsg
      }).then(response => {
        if (response.code === SETTING.NUMBER.ZERO) {
          this.questionBankInfo.status = this.questionExamineStatus
          this.questionBankInfo.failReason = this.questionExamineFailureMsg
          // sendMsg('审核完成', SETTING.STRING.MSG_BOX_STATUS.SUCCESS)
          this.$confirm('审核完成', {
            confirmButtonText: '返回列表',
            cancelButtonText: '确认',
            type: 'success'
          }).then(reason => {
            if (reason === 'confirm') {
              this.$router.back()
            }
          }).catch(e => {
            console.log(e)
          })
        } else {
          this.$message({ type: 'error', message: '审核失败' })
        }
      }).finally(() => {
        this.dialogTableVisible = false
        this.loading = false
      })
    },
    deleteQuestionBank () {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteQuestionBank({ id: this.questionBankInfo.id }).then(response => {
          if (response.code === SETTING.NUMBER.ZERO) {
            this.$message({ type: 'success', message: response.msg })
            this.$router.push('/list')
          } else {
            this.$message({ type: 'error', message: response.msg })
          }
        }).catch((err) => {
          this.$message({ type: 'error', message: err })
        })
      })
    }
  }
}
</script>

<style scoped>
#mainArea * {
  width: 100%;
}

#canvas_area * {
  width: 100%;
}

.container {
  background-color: #F1F1F1 !important;
  overflow-y: scroll;
}

.topTitle {
  margin: 30px 0;
  font-size: 14px;
}

.content_box {
  width: 80%;
  position: relative;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 22px;
  font-weight: normal;
  color: #333333;
}

.baseInfo {
  width: 100%;
  min-height: 221px;
  background-color: #FFFFFF;
  padding: 20px 40px;
  box-sizing: border-box;
  font-size: 18px;
  color: #000000;
  margin-bottom: 20px
}

.item_info {
  min-width: 230px;
  min-height: 40px;
  font-size: 14px;
  color: #333333;
}

.marginStyle {
  margin-left: 60px;
}

img {
  width: 80px;
  height: 60px;
}

.item_img_info {
  font-size: 14px;
  color: #333333;
  width: 50px;
}

.heightStyle {
  min-height: 50px;
  margin-top: 26px;
}

.btnBox {
  padding: 27px 0;
}

.btnFun {
  width: 150px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  color: #333333;
}

.btnFun_check {
  background-color: #1EB180;
  color: #FFFFFF;
  text-align: center;
}

.cursor {
  cursor: pointer;
}

.success {
  color: #67C23A
}

.warning {
  color: #E6A23C
}

.danger {
  color: #F56C6C
}

.display_top img {
  margin-right: 10px;
}

.contentArea {
  line-height: 26px;
  letter-spacing: 2px;
  width: 50%;
}

.contentArea >>> img {
  max-width: 100%;
    max-height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
</style>
